import moment from 'moment-timezone';
import { saveAs } from 'file-saver';

import SalesforceProfileService from './SalesforceProfile.service';

// Constants
import GeneralHeaders from '../constants/generalHeaders.constants';
import { eJobStatus, IProfile } from './EnedisProfile.service';
import {
  Address,
  NewProfilEnergetique,
} from '../../server/modules/delivery-points/models/delivery-point.interface';

import { formatNumber } from '../utils/number.utils';

export class DonneesTechniquesCsv {
  public downloadCsvDT(profiles: IProfile[], expertMode: boolean): void {
    const { lineHeaders, headers } = this.generateCsvHeaderDT(profiles);

    let contentLines: string = '';
    this.generateCsvLinesDT(profiles, headers, expertMode).forEach(
      (line: string) => {
        return (contentLines += line + '\n');
      }
    );

    const fileContent: string[] = [];
    fileContent.push(lineHeaders);
    fileContent.push(contentLines);
    const csvContent = fileContent.join('\n');

    const BOM = '\uFEFF';
    const csvData = BOM + csvContent;

    const blob = new Blob([csvData], {
      type: 'text/csv;charset=utf-8',
    });

    const currentDate = moment().tz('Europe/Paris').format('YYMMDD-HHmmss');
    const filename: string = `${currentDate} - DonneesTechniques.csv`;

    saveAs(blob, filename);
  }

  private generateCsvHeaderDT(profiles: IProfile[]): {
    lineHeaders: string;
    headers: string[];
  } {
    let lineHeaders = '';
    const headers = this.getCsvHeaders(profiles);

    headers.forEach((header: string) => {
      lineHeaders += header + ';';
    });

    return { lineHeaders, headers };
  }

  private generateCsvLinesDT(
    profiles: IProfile[],
    headers: string[],
    expertMode: boolean
  ): string[] {
    const lines: string[] = [];
    for (const profile of profiles) {
      let line: string = '';

      if (profile.status !== eJobStatus.FAILED) {
        for (const header of headers) {
          let value: string;
          value = SalesforceProfileService.searchGeneralHeadersValue(
            { code: header, libelle: header },
            profile.salesforce,
            expertMode
          );

          if (
            header === 'Compte Salesforce' &&
            profile.salesforce.salesforceInfo?.account?.name
          )
            value = profile.salesforce.salesforceInfo.account.name;

          if (header === 'Date limite ACD') value = value.replace('\n', '');

          if (
            header === 'Adresse' &&
            profile.salesforce.pointLivraison.address
          ) {
            value = this.formatAdressToCsv(
              profile.salesforce.pointLivraison.address
            );
          }

          if (header === 'Etat contractuel') {
            value = profile.salesforce.pointLivraison.etatContractuel || '';
          }

          if (
            header === 'Puissance Souscrite' &&
            profile.salesforce?.acheminement?.puissances?.BASE
          )
            value =
              new Intl.NumberFormat('fr-FR', {
                maximumFractionDigits: 2,
              }).format(profile.salesforce.acheminement.puissances.BASE) + 'kW';

          const valuePower =
            SalesforceProfileService.searchValueByTemporalClass(
              header.includes('Puissance') ? header.substring(10) : header,
              profile.salesforce?.acheminement?.puissances || {}
            );
          if (valuePower) value = valuePower;

          const valueConso = this.parseConsumptionValues(
            header.includes('Consommation') ? header.substring(13) : header,
            profile.salesforce
          );
          if (valueConso) value = valueConso;

          if (header === 'Programmation horaire') {
            value =
              profile.salesforce.acheminement?.programmationPosteHoraire?.replace(
                ';',
                ' &'
              ) || '';
          }

          if (value) {
            line += value + ';';
          } else {
            line += ';';
          }
        }
        lines.push(line);
      } else lines.push(`${profile.prm};Aucune information concernant ce PRM`);
    }

    return lines;
  }

  private getCsvHeaders(profiles: IProfile[]): string[] {
    const setHeaders = new Set([...GeneralHeaders].map((e) => e.libelle));

    const puissanceUniqueHeaders =
      SalesforceProfileService.displayPuissanceUniqueHeader(profiles);
    if (puissanceUniqueHeaders)
      puissanceUniqueHeaders.forEach(() =>
        setHeaders.add('Puissance Souscrite')
      );

    const puissanceHeaders =
      SalesforceProfileService.displayPuissanceHeader(profiles);
    if (puissanceHeaders)
      puissanceHeaders.forEach((e) => setHeaders.add('Puissance ' + e));

    const consoDistriHeaders =
      SalesforceProfileService.displayConsommationHeader(profiles);
    if (consoDistriHeaders)
      consoDistriHeaders.forEach((e) => setHeaders.add('Consommation ' + e));

    profiles.forEach((profile) => {
      if (profile.salesforce.acheminement?.programmationPosteHoraire) {
        setHeaders.add('Programmation horaire');
      }
    });

    setHeaders.add('Etat contractuel');

    const headers = this.reorganizedHeaderElement([...setHeaders]);

    return headers;
  }

  private reorganizedHeaderElement(headers: string[]): string[] {
    const etatContractuelFromIndex = headers.findIndex(
      (e) => e == 'Etat contractuel'
    );
    const etatContractuelElement = headers.splice(
      etatContractuelFromIndex,
      1
    )[0];
    headers.splice(1, 0, etatContractuelElement);
    return headers;
  }

  private formatAdressToCsv(address: Address): string {
    let line = '';
    for (const property in address) {
      if (property === 'commune') {
        line += address[property].libelle;
        line += ' ';
      } else {
        line += address[property];
        line += ' ';
      }
    }

    return line;
  }

  public parseConsumptionValues(
    poste: string,
    profile: NewProfilEnergetique
  ): string {
    if (
      profile.consommationData?.consommationParPoste &&
      poste in profile.consommationData?.consommationParPoste
    )
      return formatNumber(
        profile.consommationData.consommationParPoste[poste],
        {
          maximumFractionDigits: 2,
          useGrouping: false,
        }
      );
    if (poste === 'Durée')
      return SalesforceProfileService.formatPeriod(profile);

    if (poste === 'Total' && profile.consommationData?.consommationTotale)
      return formatNumber(profile.consommationData.consommationTotale, {
        maximumFractionDigits: 2,
        useGrouping: false,
      });
    return '';
  }
}

export default new DonneesTechniquesCsv();
