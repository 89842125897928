<template>
  <tr v-if="profile.status == 'FAILED'" align="center">
    <td>
      <div class="d-flex align-center mt-4 mr-2">
        <ProfilReponseStatut
          :status="profile.status"
          :profile="profile"
        ></ProfilReponseStatut>
        <div class="d-flex flex-column align-baseline">
          {{ profile.prm }}
          <ProfilEtatContrat :etatContractuel="'Erreur'"></ProfilEtatContrat>
        </div>
      </div>
    </td>
    <td
      v-bind:colspan="this.colspan"
      class="text-left pa-4"
      :style="{ color: 'var(--red-new)', fontStyle: 'italic' }"
    >
      {{ profile.message }}
    </td>
    <td v-if="showBtn" :style="{ backgroundColor: 'white !important' }">
      <div>
        <v-menu left :offset-x="true">
          <template v-slot:activator="{ on }">
            <button :style="{ backgroundColor: 'white !important' }" v-on="on">
              <v-icon class="mr-2">mdi-dots-vertical</v-icon>
            </button>
          </template>
          <v-list
            :style="{
              textAlign: 'center',
              padding: '0.5rem 1rem',
              cursor: 'pointer',
            }"
          >
            <button @click.prevent="$emit('searchProfileEnedis')">
              <v-icon class="mr-2">mdi-refresh</v-icon>
              Mettre à jour les données
            </button>
          </v-list>
        </v-menu>
      </div>
    </td>
  </tr>
</template>

<script>
  import ProfilReponseStatut from './ProfilReponseStatut';
  import ProfilEtatContrat from '../app/AppProfilEtatContrat';

  export default {
    name: 'ErrorDonnesTechniques',
    components: {
      ProfilReponseStatut,
      ProfilEtatContrat,
    },
    props: {
      profile: {
        type: Object,
      },
      showBtn: {
        type: Boolean,
      },
      colspan: {
        type: Number,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
  td {
    background-color: transparent !important;
  }
</style>
