<template>
  <v-container fluid>
    <v-row
      xs12
      class="d-flex flex-wrap"
      :style="{ overflowX: 'auto', border: 'none' }"
    >
      <v-col class="white--text" color="primary" :loading="true" sm="1">
        <v-progress-circular indeterminate color="primary" />
      </v-col>
      <v-col
        class="d-flex"
        color="primary"
        :style="{ border: 'none' }"
        :loading="true"
        sm="4"
      >
        <v-progress-linear
          class="align-self-center"
          height="10"
          color="primary"
          profiles-test="searchProgress"
          :value="this.processedPart(numberOfJobsComplete)"
        />
      </v-col>
      <v-col
        class="mt-1"
        sm="2"
        align="left"
        :style="{ color: 'var(--primary)' }"
      >
        {{ numberOfJobsComplete }} / {{ this.profiles.length }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'OptimisationLoader',
    components: {},
    props: {
      profiles: {
        type: Array,
        required: true,
      },
      numberOfJobsComplete: {
        type: Number,
        required: true,
      },
    },
    methods: {
      processedPart(numberOfJobsComplete) {
        return (numberOfJobsComplete * 100) / this.profiles.length;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
</style>
