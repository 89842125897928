<template>
  <div v-if="label" class="d-flex">
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-icon
          v-show="!loader"
          slot="label"
          v-on="on"
          :style="{ color: color, padding: '0.2rem', fontSize: '2rem' }"
        >
          {{ label }}
        </v-icon>

        <div v-show="loader" :style="{ padding: '0.4rem' }">
          <v-progress-circular
            indeterminate
            color="var(--secondary)"
            size="25"
            v-on="on"
          ></v-progress-circular>
        </div>
      </template>
      <span :style="{ textAlign: 'center' }">
        {{ text }}
      </span>
      <span :style="{ textAlign: 'center' }"> <br />{{ error }} </span>
    </v-tooltip>
  </div>
</template>

<script>
  export default {
    name: 'ProfilReponseStatut',
    props: {
      status: {
        type: String,
        required: true,
      },
      profile: {
        type: Object,
        required: false,
      },
    },
    computed: {
      loader() {
        return this.status == 'ACTIVE' ? true : false;
      },
      color() {
        switch (this.status) {
          case 'CREATE':
          case 'WAITING':
            return 'grey darken-1';
          case 'ACTIVE':
            return 'var(--secondary-new)';
          case 'FAILED':
            return 'var(--red-new)';
          case 'COMPLETED':
            return 'var(--green-new)';
          case 'PARTIAL':
            return 'var(--amber)';
          default:
            return 'var(--red-new)';
        }
      },
      label() {
        switch (this.status) {
          case 'CREATE':
          case 'WAITING':
            return 'mdi-pause-circle';
          case 'ACTIVE':
            return 'mdi-play-circle';
          case 'FAILED':
            return 'mdi-alert-circle';
          case 'COMPLETED':
            return 'mdi-checkbox-marked-circle';
          case 'PARTIAL':
            return 'mdi-alert';
          default:
            return 'mdi-alert-circle';
        }
      },
      text() {
        switch (this.status) {
          case 'CREATE':
          case 'WAITING':
            return 'En attente';
          case 'ACTIVE':
            return 'En cours';
          case 'FAILED':
          case 'FAILED ENEDIS':
            return 'Erreur :';
          case 'COMPLETED':
            return 'Complet';
          case 'PARTIAL':
            return 'Incomplet :';
          default:
            return '';
        }
      },
      error() {
        switch (this.status) {
          case 'FAILED':
          case 'FAILED ENEDIS':
            return this.displayErrorTxt();
          case 'PARTIAL':
            return this.displayErrorTxt();
          default:
            return '';
        }
      },
    },
    methods: {
      displayErrorTxt() {
        let errorTxt = '';
        if (this.profile.message) errorTxt += this.profile.message + '\n';
        if (this.profile.technicalDataResult.message)
          errorTxt += this.profile.technicalDataResult.message + '\n';
        if (this.profile.consommationResult.message)
          errorTxt += this.profile.consommationResult.message + '\n';
        return errorTxt;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';
</style>
