export enum TypeOffre {
  FIXE = 'FIXE',
  FORMULE = 'FORMULE',
  ARENH = 'ARENH',
  TRV = 'TRV',
  INDEXE = 'INDEXE',
  DECOTE = 'DECOTE',
  SANS_ENGAGEMENT = 'SANS_ENGAGEMENT',
  INDEXE_TRV = 'INDEXE_TRV',
}
