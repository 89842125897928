import moment, { Moment } from 'moment-timezone';
import {
  RegulatedFta,
  TypeCompteur,
  TypeRaccordement,
  VersionUtilisation,
} from '../../server/modules/commons/enums';
import { PowerByTemporalClass } from '../../server/modules/commons/types';

import { EnedisError } from '../../server/modules/enedis/models/enedis.interface';
import { SalesforceOperationResult } from '../../server/modules/salesforce/models/salesforce.interface';
import {
  ConsommationData,
  NewProfilEnergetique,
  PointLivraisonDto,
} from '../../server/modules/delivery-points/models/delivery-point.interface';
import { AcheminementProperties } from '../../server/modules/acheminement/acheminement.interface';
import { SalesforceProfileUpdateBroadcastMessage } from '../../server/modules/communication/socket/socket.interface';

export interface IProfile {
  prm: string;
  message?: string | Error;
  salesforce: NewProfilEnergetique;
  salesforceResult: SalesforceOperationResult;
  technicalDataResult: EnedisError;
  consommationResult: EnedisError;
  status: string;
}

export enum eJobStatus {
  WAITING = 'WAITING',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  FAILED_ENEDIS = 'FAILED ENEDIS',
  PARTIAL = 'PARTIAL',
}

export class EnedisProfileService {
  public compareAndUpdateProfile(
    response: SalesforceProfileUpdateBroadcastMessage,
    profile: IProfile
  ): IProfile {
    if (
      response.salesforce?.profile.pointLivraison &&
      profile.salesforce.pointLivraison
    ) {
      profile.salesforce.pointLivraison = this.updatePointLivraisonFromEnedis(
        response.salesforce.profile.pointLivraison,
        profile.salesforce.pointLivraison
      );
    }
    if (
      response.salesforce?.profile.acheminement &&
      profile.salesforce.acheminement
    ) {
      profile.salesforce.acheminement = this.updateAcheminementFromEnedis(
        response.salesforce.profile.acheminement,
        profile.salesforce.acheminement
      );
    }
    if (
      response.salesforce?.profile.consommationData &&
      profile.salesforce.consommationData
    ) {
      profile.salesforce.consommationData =
        this.updateConsommationDataFromEnedis(
          response.salesforce.profile.consommationData,
          profile.salesforce.consommationData
        );
    }

    return profile;
  }

  public updatePointLivraisonFromEnedis(
    pl: PointLivraisonDto,
    oldProfile: PointLivraisonDto
  ): PointLivraisonDto {
    return {
      ...oldProfile,
      prm: this.updateStringValueOrRenderDefault(pl.prm, oldProfile.prm),
      address: {
        escalierEtEtageEtAppartement: this.updateStringValueOrRenderDefault(
          pl.address?.escalierEtEtageEtAppartement,
          oldProfile.address?.escalierEtEtageEtAppartement
        ),
        batiment: this.updateStringValueOrRenderDefault(
          pl.address?.batiment,
          oldProfile.address?.batiment
        ),
        numeroEtNomVoie: this.updateStringValueOrRenderDefault(
          pl.address?.numeroEtNomVoie,
          oldProfile.address?.numeroEtNomVoie
        ),
        lieuDit: this.updateStringValueOrRenderDefault(
          pl.address?.lieuDit,
          oldProfile.address?.lieuDit
        ),
        codePostal: this.updateStringValueOrRenderDefault(
          pl.address?.codePostal,
          oldProfile.address?.codePostal
        ),
        commune: {
          code: this.updateStringValueOrRenderDefault(
            pl.address?.commune.code,
            oldProfile.address?.commune.code
          ),
          libelle: this.updateStringValueOrRenderDefault(
            pl.address?.commune.libelle,
            oldProfile.address?.commune.libelle
          ),
        },
      },
      etatContractuel: this.updateStringValueOrRenderDefault(
        pl.etatContractuel,
        oldProfile.etatContractuel
      ),
      operator: this.updateStringValueOrRenderDefault(
        pl.operator,
        oldProfile.operator
      ) as any,
    };
  }

  public updateAcheminementFromEnedis(
    pa: AcheminementProperties,
    oldProfile: AcheminementProperties
  ): AcheminementProperties {
    return {
      ...oldProfile,
      fta: this.updateStringValueOrRenderDefault(
        pa.fta,
        oldProfile.fta
      ) as RegulatedFta,
      typeCompteur: this.updateStringValueOrRenderDefault(
        pa.typeCompteur,
        oldProfile.typeCompteur
      ) as TypeCompteur,
      typeRaccordement: this.updateStringValueOrRenderDefault(
        pa.typeRaccordement,
        oldProfile.typeRaccordement
      ) as TypeRaccordement,
      puissanceRaccordement: this.updateNumberValueOrRenderDefault(
        pa.puissanceRaccordement,
        oldProfile.puissanceRaccordement
      ),
      versionUtilisation: this.updateStringValueOrRenderDefault(
        pa.versionUtilisation,
        oldProfile.versionUtilisation
      ) as VersionUtilisation,
      puissances: this.updateSfdcPuissancesOrConso(
        pa.puissances,
        oldProfile.puissances
      ),
      programmationPosteHoraire: this.updateStringValueOrRenderDefault(
        pa.programmationPosteHoraire,
        oldProfile.programmationPosteHoraire
      ),
      optionTarifaireFournisseur: this.updateStringValueOrRenderDefault(
        pa.optionTarifaireFournisseur,
        oldProfile.optionTarifaireFournisseur
      ),
    };
  }

  public updateConsommationDataFromEnedis(
    pc: ConsommationData,
    oldProfile: ConsommationData
  ): ConsommationData {
    return {
      ...oldProfile,
      consommationParPoste: this.updateSfdcPuissancesOrConso(
        pc.consommationParPoste,
        oldProfile.consommationParPoste
      ),
      consommationTotale: pc.consommationTotale
        ? pc.consommationTotale
        : oldProfile.consommationTotale,
      dateDebutReleve: pc.dateDebutReleve
        ? pc.dateDebutReleve
        : oldProfile.dateDebutReleve,
      dateFinReleve: pc.dateFinReleve
        ? pc.dateFinReleve
        : oldProfile.dateFinReleve,
      dureeReleve: this.updateNumberValueOrRenderDefault(
        pc.dureeReleve,
        oldProfile.dureeReleve
      ),
      dateDernierAppel: pc.dateDernierAppel,
      detailsReleves: pc.detailsReleves ? pc.detailsReleves : undefined,
    };
  }

  private updateStringValueOrRenderDefault(
    newValue: string | undefined,
    oldValue: string | undefined
  ): string {
    if (newValue) return newValue;
    if (oldValue) return oldValue;
    return '';
  }

  private updateNumberValueOrRenderDefault(
    newValue: number | undefined,
    oldValue: number | undefined
  ): number {
    if (newValue) return newValue;
    if (oldValue) return oldValue;
    return 0;
  }

  private updateSfdcPuissancesOrConso(
    newPuissancesOrConso: PowerByTemporalClass,
    oldPuissancesOrConso: PowerByTemporalClass
  ): PowerByTemporalClass {
    if (newPuissancesOrConso) return newPuissancesOrConso;
    if (oldPuissancesOrConso) return oldPuissancesOrConso;

    return {};
  }

  public updateEnedisStatus(
    response: SalesforceProfileUpdateBroadcastMessage,
    profile: IProfile
  ): IProfile {
    profile = this.checkOrReturnPartialStatus(response, profile);

    profile = this.checkOrReturnFailedStatus(response, profile);

    profile = this.checkOrReturnCompletedStatus(profile);

    return profile;
  }

  private checkOrReturnPartialStatus(
    response: SalesforceProfileUpdateBroadcastMessage,
    profile: IProfile
  ): IProfile {
    if (response.salesforce?.technicalDataResult?.success === false) {
      profile.technicalDataResult.success = false;
      profile.technicalDataResult.message =
        "Une erreur c'est produite à la récupération des données techniques: \n" +
        response.salesforce.technicalDataResult.message;
      profile.status = eJobStatus.PARTIAL;
    }

    if (response.salesforce?.consommationResult?.success === false) {
      profile.consommationResult.success = false;
      profile.consommationResult.message =
        "Une erreur c'est produite à la récupération des mesures détaillées: \n" +
        response.salesforce.consommationResult.message;
      profile.status = eJobStatus.PARTIAL;
    }

    return profile;
  }

  private checkOrReturnFailedStatus(
    response: SalesforceProfileUpdateBroadcastMessage,
    profile: IProfile
  ): IProfile {
    if (response.status === eJobStatus.FAILED) {
      profile.message = response.message;
      profile.status = eJobStatus.FAILED;
    }

    if (
      !profile.salesforce.salesforceInfo?.salesforceId &&
      profile.technicalDataResult.success === false
    ) {
      profile.message =
        "PRM absent de salesforce et aucune information ne provenant d'Enedis";
      profile.status = eJobStatus.FAILED;
    }

    if (
      profile.salesforce.salesforceInfo?.salesforceId &&
      profile.technicalDataResult.success === false &&
      profile.consommationResult.success === false
    )
      profile.status = eJobStatus.FAILED_ENEDIS;

    return profile;
  }

  private checkOrReturnCompletedStatus(profile: IProfile): IProfile {
    if (
      profile.technicalDataResult.success === true &&
      profile.consommationResult.success === true
    ) {
      profile.status = eJobStatus.COMPLETED;
    }

    return profile;
  }

  public getPeriodLabel(
    startDate: string | Moment,
    endDate: string | Moment
  ): string {
    if (startDate && endDate) {
      const a = moment(endDate);
      const b = moment(startDate);

      const years = a.diff(b, 'years');
      b.add(years, 'years');
      const months = a.diff(b, 'months');
      b.add(months, 'months');
      const days = a.diff(b, 'days');

      // An enedis period cannot be superior to one year
      if (years && months && days)
        return `${years} an - ${months} mois - ${days} jours`;

      if (years && months) return `${years} an - ${months} mois`;

      if (years && days) return `${years} an - ${days} jours`;

      if (months && days) return `${months} mois - ${days} jours`;

      if (years) return `${years} an`;

      if (months) return `${months} mois`;

      return `${days} jours`;
    }

    return '';
  }
}

export default new EnedisProfileService();
