<template>
  <div>
    <v-menu down :offset-y="true">
      <template v-slot:activator="{ on }">
        <v-btn x-small plain :disabled="disabled" v-on="on">
          <v-icon>mdi-tray-arrow-down</v-icon>

          {{ title }}
        </v-btn>
      </template>
      <v-list class="d-flex flex-column pa-0">
        <button
          :style="{ fontSize: '0.8em' }"
          class="pa-2"
          height="auto"
          :loading="loading"
          :disabled="disabled"
          @click.prevent="$emit('downloadCsvDT')"
        >
          Données techniques
        </button>
        <button
          :style="{ fontSize: '0.8em' }"
          class="pa-2"
          height="auto"
          :loading="loading"
          :disabled="disabled"
          @click.prevent="$emit('downloadCsvMD')"
        >
          Mesures détaillées
        </button>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: 'CsvDownloaderButton',
    props: {
      title: {
        type: String,
        required: true,
      },
      icon: {
        required: true,
      },
      loading: {
        type: Boolean,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';
</style>
