<template>
  <p :style="{ color: etatContractuelColor, fontStyle: 'italic', margin: 0 }">
    {{ etatContractuel }}
  </p>
</template>

<script>
  export default {
    name: 'AppProfilEtatContrat',
    props: {
      etatContractuel: {
        type: String,
        required: true,
      },
    },
    computed: {
      etatContractuelColor() {
        let color;
        switch (this.etatContractuel) {
          case 'En service':
          case 'ACTIF':
            color = 'var(--green-new)';
            break;
          case 'Résilié':
            color = 'var(--red-new)';
            break;
          case 'En service, en cours de résiliation':
            color = 'var(--amber)';
            break;
          case 'En cours de raccordement':
            color = 'var(--secondary)';
            break;
          case 'Improductif':
            color = 'orange';
            break;
          case 'Inaccessible':
            color = 'var(--black)';
            break;
          default:
            color = 'var(--red-new)';
        }

        return color;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';
</style>
