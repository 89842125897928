var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"80%"},scopedSlots:_vm._u([{key:"activator",fn:function({ on: dialog }){return [_c('div',[_c('v-btn',_vm._g({attrs:{"x-small":"","block":"","plain":"","disabled":!_vm.hasProfilesToAdd},on:{"click":function($event){$event.preventDefault();return _vm.filterProfilesToAdd.apply(null, arguments)}}},dialog),[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Ajout dans Salesforce ")],1)],1)]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('div',{on:{"click":function($event){$event.preventDefault();return _vm.closeDatalist.apply(null, arguments)}}},[_c('v-card',{staticClass:"d-flex flex-column",attrs:{"min-height":"400"}},[_c('v-card-title',[_c('span',{staticClass:"title__modal"},[_vm._v("Ajouter des PRM dans Salesforce")])]),_c('v-card-text',{style:({ minHeight: '350px' })},[_c('v-container',[_c('v-row',{staticClass:"flex-column"},[(!_vm.generalError)?_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c('h4',{style:({
                  alignSelf: 'center',
                  marginRight: '3rem',
                  whiteSpace: 'nowrap',
                })},[_vm._v(" 1. Choisir un compte ")]),_c('div',{style:({
                  width: '100%',
                  height: '50px',
                })},[_c('v-form',{on:{"submit":function($event){$event.preventDefault();_vm.resetForm(), _vm.findSfdcAccounts()}}},[_c('v-text-field',{staticClass:"pt-0",attrs:{"placeholder":"nom du compte Salesforce","datalistOpen":"datalist","error-messages":[..._vm.inputAccountErrors, ..._vm.errors]},on:{"change":function($event){return _vm.$v.inputAccount.$touch()},"blur":function($event){return _vm.$v.inputAccount.$touch()}},model:{value:(_vm.inputAccount),callback:function ($$v) {_vm.inputAccount=$$v},expression:"inputAccount"}})],1),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.datalistOpen),expression:"datalistOpen"}],staticClass:"datalist"},_vm._l((_vm.accounts),function(account,i){return _c('li',{key:'accounts' + i,staticClass:"datalist__li",on:{"click":function($event){return _vm.selectAndCloseDatalist(i)}}},[(account.siret)?_c('p',{staticClass:"datalist__li__value"},[_vm._v(" "+_vm._s(account.name)+" - "+_vm._s(account.siret)+" ")]):_c('p',{staticClass:"datalist__li__value"},[_vm._v(" "+_vm._s(account.name)+" ")]),_c('p',{staticClass:"datalist__li__value"},[_vm._v(" "+_vm._s(account.ownerName)+" ")])])}),0)],1),_c('button',{attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();_vm.resetForm(), _vm.findSfdcAccounts()}}},[(!_vm.searchAccountLoading)?_c('v-icon',{staticClass:"mr-2 ml-2",attrs:{"id":"search"}},[_vm._v("mdi-magnify")]):_vm._e(),(_vm.searchAccountLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"var(--primary)","size":"25"}}):_vm._e()],1)]):_vm._e(),(_vm.generalError)?_c('v-alert',{attrs:{"align":"center","dense":"","outlined":"","type":"error"}},[_c('div',{staticClass:"title"},[_vm._v("Erreur général")]),_c('pre',{staticStyle:{"overflow":"hidden"}},[_vm._v(_vm._s(JSON.stringify(_vm.generalError, null, '\t')))])]):_vm._e(),_c('div',{style:({
                minHeight: '2rem',
              })},[(_vm.errorAssociateAllBtn)?_c('v-input',{staticClass:"error-message",style:({
                  width: 'fit-content',
                  float: 'right',
                  marginRight: '1rem',
                }),attrs:{"error-messages":_vm.errorAssociateAllBtn}}):_vm._e()],1),_c('div',{staticClass:"d-flex",staticStyle:{"width":"100%"}},[_c('h4',{style:({
                  alignSelf: 'center',
                  marginRight: '3rem',
                  whiteSpace: 'nowrap',
                })},[_vm._v(" 2. Choisir les PRM ")]),_c('table',{staticStyle:{"text-align":"center"}},[_c('thead',[_c('tr',[_c('th',{staticClass:"pt-2 pb-2"},[_vm._v("PRM")]),_c('th',[_vm._v("Etat contractuel")]),_c('th',[_vm._v("Adresse")]),_c('th',[_vm._v(" Compte SF "),(!_vm.generalError)?_c('v-btn',{staticClass:"ml-2 mr-2",style:({
                          borderBottom: '1px solid white',
                          textTransform: 'lowercase',
                          minWidth: 0,
                          padding: 0,
                          borderRadius: 0,
                          height: '20px',
                          color: 'white',
                          float: 'right',
                        }),attrs:{"color":"var(--secondary)","plain":"","disabled":_vm.disableAssociatedBtn},on:{"click":function($event){$event.preventDefault();return _vm.associateToAll.apply(null, arguments)}}},[_vm._v(" associer tous ")]):_vm._e()],1)])]),_c('tbody',_vm._l((_vm.profileList),function(profile,i){return _c('tr',{key:'profiles' + i},[_c('td',[_vm._v(_vm._s(profile.prm))]),_c('td',[_c('ProfilEtatContrat',{style:({ textAlign: 'center' }),attrs:{"etatContractuel":profile.salesforce.pointLivraison.etatContractuel
                            ? profile.salesforce.pointLivraison
                                .etatContractuel
                            : 'Aucune information'}})],1),_c('td',[(profile.salesforce.pointLivraison.address)?_c('ProfileAddress',{attrs:{"address":_vm.parseAdressProfilSF(
                            profile.salesforce.pointLivraison.address
                          )}}):_vm._e()],1),(!profile.account)?_c('td',[(!_vm.generalError)?_c('v-btn',{attrs:{"plain":"","color":"var(--secondary)","disabled":_vm.disableAssociatedBtn},on:{"click":function($event){$event.preventDefault();return _vm.associateAccount(profile)}}},[_vm._v(" ASSOCIER ")]):_vm._e()],1):_vm._e(),(profile.account)?_c('td',[_c('div',{staticClass:"d-flex justify-space-between",style:({ color: 'var(--secondary)' })},[_c('div'),_c('div',[_c('p',{style:({ padding: '0.2rem', margin: 0 })},[_vm._v(" "+_vm._s(profile.account.name)+" ")]),_c('p',{style:({ padding: '0.2rem', margin: 0 })},[_vm._v(" "+_vm._s(profile.account.ownerName)+" ")])]),_c('div',{style:({ alignSelf: 'center' })},[_c('v-btn',{attrs:{"plain":""},on:{"click":function($event){$event.preventDefault();return _vm.cancelAssociation(profile)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)])]):_vm._e()])}),0)])])],1)],1)],1),_c('div',{staticClass:"d-flex justify-center pa-8"},[_c('v-btn',{staticClass:"new-button__secondary",attrs:{"color":"secondary","text":""},on:{"click":_vm.closeModal}},[_vm._v(" Annuler ")]),(!_vm.generalError)?_c('v-btn',{staticClass:"new-button",attrs:{"color":"secondary","disabled":_vm.disableCreationToSfdcBtn},on:{"click":function($event){$event.preventDefault();return _vm.createSfdcProfiles.apply(null, arguments)}}},[_vm._v(" CREER CES PRM DANS SALESFORCE ")]):_vm._e()],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }