<template>
  <v-container fluid>
    <v-row xs12 :style="{ overflowX: 'auto', overflowY: 'hidden' }">
      <v-col sm="12">
        <table>
          <thead>
            <tr :style="{ whiteSpace: 'nowrap' }">
              <th></th>
              <th :colspan="3">Données Salesforce</th>
              <th :colspan="6 + headersPuissanceUnique.length">
                Données Techniques
              </th>
              <th
                v-if="headersPuissance.length > 0"
                :colspan="headersPuissance.length"
              >
                Puissance (en kW)
              </th>
              <th
                v-if="headersDistriConso.length > 0"
                :colspan="headersDistriConso.length"
              >
                Consommations Distributeur (en MWh)
              </th>
              <th
                v-if="headersFourniConso.length > 0"
                :colspan="headersFourniConso.length"
              >
                Consommations Fournisseur (en MWh)
              </th>
            </tr>

            <tr align="center">
              <th>PRM</th>
              <th v-for="(header, i) in generalHeaders" :key="'header' + i">
                {{ header.libelle }}
              </th>
              <th
                v-for="(header, i) of headersPuissanceUnique"
                :key="'headerPuissanceUnique' + i"
              >
                {{ header }}
              </th>
              <th
                v-for="(header, i) of headersPuissance"
                :key="'headerPuissance' + i"
              >
                {{ header }}
              </th>
              <th
                v-for="(header, i) in headersDistriConso"
                :key="'headersDistriConso' + i"
                :class="{ 'col-total': header === 'Total' }"
              >
                {{ header }}
              </th>
              <th
                v-for="(header, i) in headersFourniConso"
                :key="'headersFourniConso' + i"
                :class="{ 'col-total': header === 'Total' }"
              >
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody
            v-for="(profile, i) of profiles"
            :key="'profiles' + i"
            :class="{ light: i % 2 == 0, shadow: i % 2 == 1 }"
          >
            <tr
              v-if="profile.status == 'ACTIVE' || profile.status == 'CREATE'"
              align="center"
            >
              <td>
                <div class="d-flex align-center mt-4 mr-2">
                  <ProfilReponseStatut
                    :status="profile.status"
                  ></ProfilReponseStatut>
                  <div class="d-flex flex-column align-baseline">
                    {{ profile.prm }}
                  </div>
                </div>
              </td>
              <td
                v-bind:colspan="
                  generalHeaders.length +
                  headersPuissance.length +
                  headersDistriConso.length +
                  headersFourniConso.length +
                  headersPuissanceUnique.length
                "
              ></td>
            </tr>

            <ErrorDonnesTechniques
              :profile="profile"
              :showBtn="showBtn"
              :colspan="
                generalHeaders.length +
                headersPuissance.length +
                headersDistriConso.length +
                headersFourniConso.length +
                headersPuissanceUnique.length
              "
              @searchProfileEnedis="searchProfileEnedis(profile)"
            ></ErrorDonnesTechniques>

            <tr
              v-if="
                profile.status == 'COMPLETED' ||
                profile.status == 'PARTIAL' ||
                profile.status == 'FAILED ENEDIS'
              "
              align="center"
            >
              <td>
                <div class="d-flex align-center">
                  <ProfilReponseStatut
                    :status="profile.status"
                    :profile="profile"
                  ></ProfilReponseStatut>
                  <div class="d-flex flex-column align-baseline mt-4 mr-2">
                    {{ profile.prm }}
                    <ProfilEtatContrat
                      :style="{ textAlign: 'left' }"
                      :etatContractuel="
                        profile.salesforce.pointLivraison.etatContractuel
                          ? profile.salesforce.pointLivraison.etatContractuel
                          : 'Aucune information'
                      "
                    ></ProfilEtatContrat>
                    <div
                      v-if="profile.salesforceResult.success === false"
                      class="error"
                      :style="{ textAlign: 'left' }"
                    >
                      {{ profile.salesforceResult.message }}
                    </div>
                  </div>
                </div>
              </td>

              <td v-for="(header, i) in generalHeaders" :key="'header' + i">
                <div v-if="header.code === 'Address'">
                  <ProfileAddress
                    v-if="profile.salesforce.pointLivraison.address"
                    :address="
                      parseAdressProfilSF(
                        profile.salesforce.pointLivraison.address
                      )
                    "
                  ></ProfileAddress>
                </div>
                <div v-else-if="header.code === 'Compte Salesforce'">
                  <div
                    v-if="profile.salesforce.salesforceInfo.salesforceId"
                    class="d-flex justify-center"
                  >
                    <a
                      target="new"
                      :href="`${salesforceUrl}/Account/${profile.salesforce.salesforceInfo.salesforceId}/view`"
                      class="d-flex justify-center"
                      :style="{ textDecoration: 'none' }"
                    >
                      <v-icon
                        :style="{ color: '#009CDB', marginRight: '0.5rem' }"
                        >mdi-salesforce
                      </v-icon>
                    </a>
                    <div>
                      <p>
                        {{
                          searchGeneralHeadersValue(
                            header,
                            profile.salesforce,
                            expertMode
                          )
                        }}
                      </p>
                      {{ profile.salesforce.salesforceInfo.account.ownerName }}
                    </div>
                  </div>
                  <div v-else class="error-message">
                    PRM absent de salesforce
                  </div>
                </div>
                <div
                  v-else-if="header.code === 'Date limite ACD'"
                  :class="classError(header, profile.salesforce)"
                >
                  <div
                    v-show="
                      !profile.salesforce.salesforceInfo.account
                        .enedisAuthorization.isAuthorized &&
                      profile.consommationResult.success === false
                    "
                  >
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          slot="label"
                          v-on="on"
                          :style="{
                            color: 'var(--red-new)',
                            padding: '0.2rem',
                            fontSize: '2rem',
                          }"
                        >
                          mdi-alert-circle
                        </v-icon>
                      </template>
                      <span :style="{ textAlign: 'center' }">
                        Impossible de mettre à jour les données sans ACD valide
                      </span>
                    </v-tooltip>
                  </div>
                  {{
                    searchGeneralHeadersValue(
                      header,
                      profile.salesforce,
                      expertMode
                    )
                  }}
                </div>
                <div v-else :class="classError(header, profile.salesforce)">
                  {{
                    searchGeneralHeadersValue(
                      header,
                      profile.salesforce,
                      expertMode
                    )
                  }}
                </div>
              </td>
              <td
                v-for="(header, i) of headersPuissanceUnique"
                :key="'headerPuissanceUnique' + i"
              >
                <div v-if="profile.salesforce.acheminement.puissances.BASE">
                  {{ profile.salesforce.acheminement.puissances.BASE + 'kW' }}
                </div>
              </td>
              <td
                v-for="(header, i) of headersPuissance"
                :key="'headerPuissance' + i"
              >
                {{ searchPuissanceValue(header, profile) }}
              </td>

              <td
                v-for="(header, i) in headersDistriConso"
                :key="'headersDistriConso' + i"
                :class="classObject(header, profile.profile)"
              >
                {{ searchConsommationValue(header, profile) }}
              </td>

              <td
                v-for="(header, i) in headersFourniConso"
                :key="'headersFourniConso' + i"
                :class="classObject(header, profile.profile)"
              >
                {{ searchConsommationFrnValue(header, profile) }}
              </td>

              <td
                v-if="showBtn"
                :style="{ backgroundColor: 'white !important' }"
              >
                <div>
                  <v-menu left :offset-x="true">
                    <template v-slot:activator="{ on }">
                      <button
                        :style="{ backgroundColor: 'white !important' }"
                        v-on="on"
                      >
                        <v-icon class="mr-2">mdi-dots-vertical</v-icon>
                      </button>
                    </template>
                    <v-list
                      :style="{
                        textAlign: 'center',
                        padding: '0.5rem 1rem',
                        cursor: 'pointer',
                      }"
                    >
                      <button
                        @click.prevent="
                          $emit('searchProfilesEnedis', [profile])
                        "
                      >
                        <v-icon class="mr-2">mdi-refresh</v-icon>
                        Mettre à jour les données
                      </button>
                    </v-list>
                  </v-menu>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import GeneralHeaders from '../../constants/generalHeaders.constants';

  import { SalesforceProfileService } from '../../services';

  import ProfileAddress from '../app/AppProfileAddress';
  import ProfilEtatContrat from '../app/AppProfilEtatContrat';
  import ProfilReponseStatut from './ProfilReponseStatut';
  import ErrorDonnesTechniques from './ErrorDonnesTechniques';
  import moment from 'moment';

  export default {
    name: 'SearchContainer',
    components: {
      ProfileAddress,
      ProfilEtatContrat,
      ProfilReponseStatut,
      ErrorDonnesTechniques,
    },
    props: {
      profiles: {
        type: Array,
        required: true,
      },
      expertMode: {
        type: Boolean,
        required: true,
      },
      showBtn: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        salesforceUrl: process.env.VUE_APP_SFDC_URL,
      };
    },
    computed: {
      generalHeaders() {
        const generalHeaders = [...GeneralHeaders];
        generalHeaders.splice(0, 1);
        return generalHeaders;
      },
      headersPuissanceUnique() {
        return SalesforceProfileService.displayPuissanceUniqueHeader(
          this.profiles
        );
      },
      headersPuissance() {
        return SalesforceProfileService.displayPuissanceHeader(this.profiles);
      },
      headersDistriConso() {
        return SalesforceProfileService.displayConsommationHeader(
          this.profiles
        );
      },
      headersFourniConso() {
        return SalesforceProfileService.displayFournisseurHeader(this.profiles);
      },
    },
    methods: {
      classObject(header, profile) {
        let dureeValueLabel;
        if (header === 'Duree') {
          dureeValueLabel = this.searchConsommationValue(header, profile);
        }
        return {
          'col-total': header === 'Total',
          'duree-not-year': header === 'Duree' && dureeValueLabel !== '1 an',
        };
      },
      searchProfileEnedis(profile) {
        this.$emit('searchProfilesEnedis', [profile]);
      },
      classError(header, profile) {
        if (header.code === 'Date limite ACD') {
          if (
            !profile.salesforceInfo?.account?.enedisAuthorization
              .authorizedUntil ||
            moment(
              profile.salesforceInfo.account.enedisAuthorization.authorizedUntil
            ).isBefore(moment())
          )
            return 'error-message';
        }
        if (header.code === 'Fta') {
          if (!profile.acheminement?.fta) return 'error-message unwrap';
        }
      },
      searchGeneralHeadersValue(header, profile, expertMode) {
        if (profile) {
          return SalesforceProfileService.searchGeneralHeadersValue(
            header,
            profile,
            expertMode
          );
        }
        return '';
      },
      parseAdressProfilSF(adresse) {
        return {
          numeroEtNomVoie: adresse.numeroEtNomVoie,
          codePostal: adresse.codePostal,
          commune: { libelle: adresse.commune.libelle },
        };
      },
      searchPuissanceValue(header, profile) {
        if (profile) {
          return SalesforceProfileService.searchValueByTemporalClass(
            header,
            profile.salesforce.acheminement.puissances
          );
        }
      },
      searchConsommationValue(header, profile) {
        if (profile) {
          return SalesforceProfileService.searchConsommationValue(
            header,
            profile.salesforce
          );
        }
      },
      searchConsommationFrnValue(header, profile) {
        if (profile) {
          return SalesforceProfileService.searchConsommationFrnValue(
            header,
            profile.salesforce
          );
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/table.scss';
  @import 'src/client/style/constants.scss';
  .light {
    background-color: #f9f9f9 !important;
  }

  .shadow {
    background-color: #eeeeee !important;
  }

  td {
    background-color: transparent !important;
  }

  .col-total {
    font-weight: bold;
  }

  .duree-not-year {
    font-weight: bold;
    color: #ffbf00;
  }

  .unwrap {
    white-space: nowrap;
  }
</style>
