interface ResolvedNumberFormatOptions {
  locale?: string;
  numberingSystem?: string;
  style?: string;
  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  useGrouping?: boolean;
}

export function formatNumber(
  number: number,
  options?: ResolvedNumberFormatOptions
): string {
  return new Intl.NumberFormat('fr-FR', {
    ...options,
  }).format(number);
}
