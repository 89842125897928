export enum TypeCompteurPower {
  C5 = 'C5',
  C4 = 'C4',
  C3 = 'C3',
  C2 = 'C2',
  C1 = 'C1',
}

export enum TypeCompteurGas {
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  TP = 'TP',
}
export enum TypeCompteur {
  C5 = 'C5',
  C4 = 'C4',
  C3 = 'C3',
  C2 = 'C2',
  C1 = 'C1',
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
  T4 = 'T4',
  TP = 'TP',
}
