export enum ProfilConsommateurGaz {
  P011 = 'P011',
  P012 = 'P012',
  P013 = 'P013',
  P014 = 'P014',
  P015 = 'P015',
  P016 = 'P016',
  P017 = 'P017',
  P018 = 'P018',
  P019 = 'P019',
}
