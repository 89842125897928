export const codeNafFormat = (codeNaf) => {
  const regexCodeNaf = /[\d]{4}[a-z]{1}/gim;
  return regexCodeNaf.test(codeNaf);
};

export const currentSearchMin = (currentSearch) => {
  return currentSearch !== '';
};

export const accountsEmpty = (accounts) => {
  return accounts.length !== 0;
};
