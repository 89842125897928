<template>
  <div :style="{ backgroundColor: 'var(--secondary-grey-new) !important' }">
    <v-card class="pa-6" v-if="!isLoading" outlined>
      <v-row align="center" class="d-flex">
        <v-col lg="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                small
                block
                color="primary"
                @click.prevent="searchProfilesSF"
                data-test="submitDt"
                v-on="on"
              >
                Lancer une consultation
              </v-btn>
            </template>
            <div :style="{ textAlign: 'center' }">
              Affiche les données renseignées dans Salesforce.
            </div>
          </v-tooltip>
        </v-col>

        <v-col>
          <v-row align="center" class="d-flex justify-end">
            <div lg="1" class="mr-4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-checkbox
                    v-show="showBtn && !generalError"
                    v-on="on"
                    v-model="expertMode"
                    :class="'ma-0 pa-0'"
                    hide-details
                    data-test="codeFTACheckbox"
                  >
                    <span
                      slot="label"
                      :style="{
                        whiteSpace: 'nowrap',
                        fontSize: '0.7em',
                        opacity: '0.62',
                        fontWeight: '500',
                      }"
                      v-on="on"
                      >CODE FTA</span
                    >
                  </v-checkbox>
                </template>
                <div :style="{ textAlign: 'center' }">
                  Cocher pour avoir la Formule Tarifaire d'Acheminement affichée
                  en code plutôt qu'en français. <br />Utile pour un traitement
                  numérique ou un affichage plus condensé.
                </div>
              </v-tooltip>
            </div>

            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    v-show="showBtn && !generalError"
                    block
                    plain
                    @click.prevent="searchProfilesEnedis(profileList)"
                    data-test="submitDt"
                    v-on="on"
                  >
                    <v-icon>mdi-refresh</v-icon>
                    Données enedis
                  </v-btn>
                </template>
                <div :style="{ textAlign: 'center' }">
                  Récupère les données auprès Enedis.
                </div>
              </v-tooltip>
            </div>

            <ModalAddProfilEnergetiqueSfdc
              v-show="showBtn && !generalError"
              :profiles="profileList"
              :hasProfilesToAdd="hasProfilesToAdd"
              @createProfileSfdc="createProfileSfdc"
            />

            <CsvDownloaderButton
              v-show="showBtn && !generalError"
              title="TÉLÉCHARGEMENT"
              icon="mdi-file-delimited"
              :disabled="isLoading"
              :profiles="profileList"
              :loading="exportProgress"
              @downloadCsvDT="downloadCsvDT"
              @downloadCsvMD="downloadCsvMD"
            />
          </v-row>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="pt-2 pb-3" v-if="isLoading" outlined>
      <v-row align="center">
        <v-col md="12">
          <OptimisationLoader
            v-if="!generalError"
            class="text-center"
            :profiles="profileList"
            :numberOfJobsComplete="progressValue"
          />

          <v-alert
            v-if="generalError"
            align="center"
            dense
            outlined
            type="error"
          >
            <div class="title">Erreur général</div>
            <pre :style="{ overflow: 'hidden' }">{{
              JSON.stringify(generalError, null, '\t')
            }}</pre>
          </v-alert>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="mt-2" v-if="showResult" outlined>
      <v-row align="center">
        <v-col md="12">
          <SearchContainer
            v-if="!generalError"
            :profiles="profileList"
            :expert-mode="expertMode"
            :showBtn="showBtn"
            @searchProfilesEnedis="searchProfilesEnedis"
            data-test="singleSearchContainer"
          />
          <v-alert
            v-if="generalError"
            align="center"
            dense
            outlined
            type="error"
          >
            <div class="title">Erreur général</div>
            <pre>{{ JSON.stringify(generalError, null, '\t') }}</pre>
          </v-alert>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
  import socket from '../../socket';
  import { mapState } from 'vuex';
  import { required } from 'vuelidate/lib/validators';
  import {
    minPrmLengthArray,
    maxPrmLengthArray,
    formatPrmArray,
  } from '../../validators/enedisValidators';
  import { saveAs } from 'file-saver';

  import {
    EnedisProfileService,
    DonneesTechniquesCsv,
    SalesforceProfileService,
  } from '../../services';

  import SearchContainer from './SearchContainer';
  import CsvDownloaderButton from './CsvDownloaderButton';
  import OptimisationLoader from '../acheminement/OptimisationLoader';
  import ModalAddProfilEnergetiqueSfdc from './ModalAddProfilEnergetiqueSfdc';
  import { eJobStatus } from '../../services/EnedisProfile.service';
  import businessDataApiService from '../../services/business-data/business-data.service';

  import {
    MatomoService,
    MATOMO_EVENTS,
  } from './../../services/MatomoApi.service';
  import moment from 'moment-timezone';

  export default {
    name: 'DonneesTechniquesTabs',
    components: {
      SearchContainer,
      CsvDownloaderButton,
      OptimisationLoader,
      ModalAddProfilEnergetiqueSfdc,
    },
    props: {
      prms: {
        type: Array,
      },
      validation: {
        type: Function,
      },
    },
    data() {
      return {
        expertMode: true,
        generalError: null,
        isLoading: false,
        progressValue: 0,
        prmsLength: 0,
        profileList: [],
        showResult: false,
        showBtn: false,
        hasProfilesToAdd: false,
        exportProgress: false,
      };
    },
    watch: {
      profileList(profileList) {
        const jobsDone = profileList.filter((profile) => {
          return profile.status == 'COMPLETED' || profile.status == 'FAILED';
        });
        if (this.prmsLength === jobsDone.length) {
          this.showBtn = true;
        }
        for (const profile of profileList) {
          if (
            profile.message === 'PRM absent de salesforce' ||
            profile.message ===
              "PRM absent de salesforce et aucune information ne provenant d'Enedis"
          )
            this.hasProfilesToAdd = true;
        }
      },
      expertMode(expertMode) {
        localStorage.expertMode = expertMode;
      },
      progressValue() {
        if (this.progressValue === this.prmsLength) {
          this.isLoading = false;
        }
      },
    },
    computed: {
      ...mapState('auth', ['user']),
    },

    created() {
      socket.on('salesforce:profile:fetch', (response) => {
        const profile = this.profileList.find((e) => e.prm === response.prm);

        this.modifyProfileList({ ...profile, ...response });

        const jobFinishedStatus = ['COMPLETED', 'FAILED'];
        if (jobFinishedStatus.includes(response.status)) {
          this.progressValue += 1;
        }
      });

      socket.on('salesforce:profile:update', (response) => {
        const profile = this.profileList.find((e) => e.prm === response.prm);

        this.updatePrmEnedis(response, profile);

        const jobFinishedStatus = ['COMPLETED', 'FAILED'];
        if (jobFinishedStatus.includes(response.status)) {
          this.progressValue += 1;
        }
      });

      socket.on('salesforce:profile:create', (response) => {
        const profile = this.profileList.find((e) => e.prm === response.prm);
        profile.status = response.status;

        if (response.status === eJobStatus.COMPLETED) {
          Object.assign(profile.salesforce.salesforceInfo, {
            salesforceId: response.pointLivraisonReference.salesforceId,
          });
          Object.assign(profile.salesforce.salesforceInfo, {
            account: response.pointLivraisonReference.account,
          });
        }

        if (response.status === eJobStatus.FAILED) {
          profile.message = response.message;
        }

        this.modifyProfileList(profile);

        const jobFinishedStatus = [eJobStatus.COMPLETED, eJobStatus.FAILED];
        if (jobFinishedStatus.includes(response.status)) {
          this.progressValue += 1;
        }
      });
    },
    mounted() {
      MatomoService.trackPageView('DT & conso');
      const exportMode = localStorage.getItem('expertMode');
      this.expertMode = exportMode === null ? true : JSON.parse(exportMode);
    },
    validations: {
      prms: {
        required,
        minPrmLengthArray,
        maxPrmLengthArray,
        formatPrmArray,
      },
    },
    methods: {
      async searchProfilesSF() {
        this.$v.$touch();
        this.validation();

        if (!this.$v.$invalid) {
          MatomoService.trackEvent(
            MATOMO_EVENTS.consultSalesforceProfiles,
            this.prms.length
          );

          try {
            this.generalError = null;
            this.profileList = [];
            this.showResult = false;
            this.showBtn = false;
            this.progressValue = 0;
            this.prmsLength = [...new Set(this.prms)].length;
            this.isLoading = true;

            for (const prm of [...new Set(this.prms)]) {
              this.profileList.push({
                prm,
                status: 'CREATE',
                salesforce: {
                  pointLivraison: {},
                  consommationData: {},
                  acheminement: {},
                  salesforceInfo: {},
                },
                technicalDataResult: {},
                consommationResult: {},
                salesforceResult: {},
              });
            }

            const response = await this.$http.get('/salesforce/profiles', {
              params: {
                prms: [...new Set(this.prms)],
              },
              withCredentials: true,
            });
            this.checkData(response);
            this.showResult = true;
          } catch (err) {
            if (
              err.response &&
              err.response.data &&
              err.response.data.message
            ) {
              this.generalError = err.response.data.message;
            } else {
              this.generalError = err.message;
            }

            this.isLoading = false;
            this.showResult = true;
          }
        }
      },
      async searchProfilesEnedis(profiles) {
        this.generalError = null;
        this.progressValue = 0;
        this.prmsLength = profiles.length;

        try {
          this.validation();
          MatomoService.trackEvent(
            MATOMO_EVENTS.updateEnedisProfiles,
            this.prms.length
          );

          this.isLoading = true;

          if (profiles.length === 1) {
            this.profileList = this.profileList.map((e) => {
              if (e.prm == profiles[0].prm) {
                return {
                  ...e,
                  status: 'CREATE',
                  technicalDataResult: {},
                  consommationResult: {},
                  salesforceResult: {},
                };
              } else return e;
            });
          } else {
            this.profileList = this.profileList.map((e) => {
              return {
                ...e,
                status: 'CREATE',
                technicalDataResult: {},
                consommationResult: {},
                salesforceResult: {},
              };
            });
          }

          const response = await this.$http.put(
            '/salesforce/profiles',
            {
              params: {
                profiles: profiles.map((e) => {
                  return {
                    username: this.user.displayName,
                    prm: e.prm,
                    salesforceId: e.salesforce.salesforceInfo.salesforceId,
                    autorisationClient:
                      SalesforceProfileService.checkAcdIsValid(e.salesforce),
                  };
                }),
              },
            },
            { withCredentials: true }
          );
          this.checkData(response);
        } catch (err) {
          if (err.response?.data?.message)
            this.generalError = err.response.data.message;
          else this.generalError = err.message;

          this.isLoading = false;
          this.showResult = true;
        }
      },
      checkData(response) {
        if (!response.data) throw new Error('Aucune données disponible');
      },
      updatePrmEnedis(response, profile) {
        if (response.status === 'COMPLETED' || response.status === 'FAILED') {
          profile.technicalDataResult = response.salesforce.technicalDataResult;
          profile.consommationResult = response.salesforce.consommationResult;
          profile.salesforceResult = response.salesforce.salesforceResult;

          let profileUpdated = EnedisProfileService.compareAndUpdateProfile(
            response,
            profile
          );
          profileUpdated = EnedisProfileService.updateEnedisStatus(
            response,
            profile
          );

          return this.modifyProfileList(profileUpdated);
        }

        return this.modifyProfileList({
          ...profile,
          ...response,
        });
      },
      modifyProfileList(profileUpdated) {
        this.profileList = this.profileList.map((e) => {
          if (e.prm === profileUpdated.prm) return profileUpdated;
          return e;
        });
      },
      downloadCsvDT() {
        MatomoService.trackEvent(MATOMO_EVENTS.downloadCsvTechnicalData);

        this.exportProgress = true;

        DonneesTechniquesCsv.downloadCsvDT(this.profileList, this.expertMode);

        this.exportProgress = false;
      },
      async downloadCsvMD() {
        MatomoService.trackEvent(MATOMO_EVENTS.downloadCsvDetailledMeasurement);

        try {
          this.exportProgress = true;

          const prms = this.profileList.map((profile) => profile.prm);

          const parameters = {
            readingPerimeter: {
              prms,
              startDate: moment().subtract(3, 'years'),
              endDate: new Date(),
            },
            userEmail: this.user.emails[0].value,
          };

          const { blob, filename } =
            await businessDataApiService.exportReadings(parameters);

          const currentDate = moment()
            .tz('Europe/Paris')
            .format('YYMMDD-HHmmss');

          saveAs(blob, filename ?? `${currentDate} - MesuresDetaillees.csv`);
        } catch (err) {
          let message;
          if (err.response && err.response.data && err.response.data.message)
            message = err.response.data.message;
          else message = err.message;

          this.generalError = message;
        } finally {
          this.exportProgress = false;
        }
      },
      createProfileSfdc(profiles) {
        MatomoService.trackEvent(
          MATOMO_EVENTS.createDeliveryPoints,
          profiles.length
        );

        this.generalError = null;
        this.progressValue = 0;
        this.showBtn = false;
        this.hasProfilesToAdd = false;
        this.isLoading = true;
        this.prmsLength = profiles.length;
        this.hasProfilesToAdd = false;

        for (const profile of profiles) {
          if (profile) {
            this.profileList = this.profileList.map((e) => {
              if (e.prm === profile.prm)
                return { ...e, status: 'CREATE', message: '' };
              return e;
            });
          }
        }
      },
    },
  };
</script>
