export enum RegulatedTemporalClass {
  BASE = 'BASE',
  HP = 'HP',
  HC = 'HC',
  PTE = 'PTE',
  HPH = 'HPH',
  HCH = 'HCH',
  HPE = 'HPE',
  HPB = 'HPB',
  HCE = 'HCE',
  HCB = 'HCB',
}

export const orderedTemporalClass: RegulatedTemporalClass[] = [
  RegulatedTemporalClass.BASE,
  RegulatedTemporalClass.HP,
  RegulatedTemporalClass.HC,
  RegulatedTemporalClass.PTE,
  RegulatedTemporalClass.HPH,
  RegulatedTemporalClass.HCH,
  RegulatedTemporalClass.HPE,
  RegulatedTemporalClass.HPB,
  RegulatedTemporalClass.HCE,
  RegulatedTemporalClass.HCB,
];
