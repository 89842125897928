export enum RegulatedFta {
  BTINFCUST = 'BTINFCUST',
  BTINFMUDT = 'BTINFMUDT',
  BTINFLU = 'BTINFLU',
  BTINFCU4 = 'BTINFCU4',
  BTINFMU4 = 'BTINFMU4',
  BTSUPCU = 'BTSUPCU',
  BTSUPLU = 'BTSUPLU',
  BTSUPMU = 'BTSUPMU',
  BTSUPCU4 = 'BTSUPCU4',
  BTSUPLU4 = 'BTSUPLU4',
  HTA5 = 'HTA5',
  HTA8 = 'HTA8',
  HTASANSDIFF = 'HTASANSDIFF',
  HTACU5 = 'HTACU5',
  HTALU5 = 'HTALU5',
  HTACUPM5 = 'HTACUPM5',
  HTALUPM5 = 'HTALUPM5',
}
