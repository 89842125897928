<template>
  <div>
    <div>
      {{ address.batiment }}
    </div>
    <div>
      {{ address.escalierEtEtageEtAppartement }}
    </div>
    <div>
      {{ address.numeroEtNomVoie }}
    </div>
    <div>
      {{ address.codePostal }}
    </div>
    <div>
      {{ address.commune.libelle }}
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AppProfileAddress',
    props: {
      address: {
        type: Object,
        required: true,
      },
    },
  };
</script>
