export default Object.freeze(
  new Set([
    {
      code: 'PRM',
      libelle: 'PRM',
    },
    {
      code: 'Compte Salesforce',
      libelle: 'Compte Salesforce',
    },
    {
      code: 'Date limite ACD',
      libelle: 'Date limite ACD',
    },
    {
      code: 'MAJ Enedis',
      libelle: 'MAJ Enedis',
    },
    {
      code: 'Address',
      libelle: 'Adresse',
    },
    {
      code: 'Operateur',
      libelle: 'Operateur',
    },
    {
      code: 'Fta',
      libelle: 'Fta',
    },
    {
      code: 'Type Compteur',
      libelle: 'Type Compteur',
    },
    {
      code: 'Option Tarifaire Fournisseur',
      libelle: 'Option Tarifaire Fournisseur',
    },
    {
      code: 'Puissance Raccordement',
      libelle: 'Puissance Raccordement',
    },
  ])
);
