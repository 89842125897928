import moment from 'moment-timezone';
import EnedisProfileService, { IProfile } from './EnedisProfile.service';
import posteHorosaisonnierOrder, {
  orderedTemporalClass,
} from '../constants/posteHorosaisonnierOrder.constants';
import { PowerByTemporalClass } from '../../server/modules/commons/types';
import { RegulatedTemporalClass } from '../../server/modules/commons/enums';
import { NewProfilEnergetique } from '../../server/modules/delivery-points/models/delivery-point.interface';

export interface IHeader {
  code: string;
  libelle: string;
}

export class SalesforceProfileService {
  public searchGeneralHeadersValue(
    header: IHeader,
    profile: NewProfilEnergetique,
    expertMode: boolean
  ): string {
    let value = '';
    switch (header.code) {
      case 'PRM':
        value = profile.pointLivraison.prm ? profile.pointLivraison.prm : '';
        break;
      case 'Operateur':
        value = profile.pointLivraison.operator
          ? profile.pointLivraison.operator
          : '';
        break;
      case 'Type Compteur':
        value = profile.acheminement?.typeCompteur || '';
        break;
      case 'Fta':
        if (!profile.acheminement?.fta) value = 'FTA invalide';
        else
          value = expertMode
            ? this.translateFtaFromCode(profile.acheminement?.fta)
            : profile.acheminement.fta;
        break;
      case 'Compte Salesforce':
        value = profile.salesforceInfo?.account?.name
          ? profile.salesforceInfo.account.name
          : '';
        break;
      case 'Date limite ACD':
        if (
          !profile.salesforceInfo?.account?.enedisAuthorization.authorizedUntil
        ) {
          value = 'ACD invalide';
        } else if (
          moment(
            profile.salesforceInfo.account.enedisAuthorization.authorizedUntil
          ).isBefore(moment())
        ) {
          value =
            moment(
              profile.salesforceInfo.account.enedisAuthorization.authorizedUntil
            ).format('DD/MM/YYYY') + '\n ACD invalide';
        } else {
          value = moment(
            profile.salesforceInfo.account.enedisAuthorization.authorizedUntil
          ).format('DD/MM/YYYY');
        }
        break;
      case 'MAJ Enedis':
        value = profile.consommationData?.dateDernierAppel
          ? moment(profile.consommationData.dateDernierAppel).format(
              'DD/MM/YYYY'
            )
          : '-';
        break;
      case 'Option Tarifaire Fournisseur':
        value = profile.acheminement?.optionTarifaireFournisseur
          ? profile.acheminement.optionTarifaireFournisseur
          : '';
        break;
      case 'Puissance Raccordement':
        value = profile.acheminement?.puissanceRaccordement
          ? profile.acheminement.puissanceRaccordement.toString() + 'kW'
          : '';
        break;
    }

    return value;
  }

  public checkAcdIsValid(profile: NewProfilEnergetique): boolean {
    if (
      profile.salesforceInfo?.account?.enedisAuthorization?.isAuthorized ===
        true &&
      moment(
        profile.salesforceInfo.account.enedisAuthorization.authorizedUntil
      ).isAfter(moment())
    )
      return true;

    return false;
  }

  private translateFtaFromCode(fta: string): string {
    let value = '';
    switch (fta) {
      case 'BTINFCUST':
        value =
          'TarifBT<=36kVA Courte Utilisation sans différenciation temporelle';
        break;
      case 'BTINFMUDT':
        value =
          'Tarif BT<=36kVA Moyenne Utilisation heures pleines heures creuses';
        break;
      case 'BTINFLU':
        value =
          'Tarif BT<=36kVA Longue Utilisation sans différenciation temporelle';
        break;
      case 'BTINFCU4':
        value =
          'Tarif  BT<=36kVA  Courte  Utilisation heures  pleines  heures  creuses  associées à deux saisons';
        break;
      case 'BTINFMU4':
        value =
          'Tarif   BT<=36kVA   Moyenne   Utilisation   heures   pleines   heures   creuses associées à deux saisons';
        break;
      case 'BTSUPLU':
        value = 'TURPE 4 -BT>36kVA Longue Utilisation ';
        break;
      case 'BTSUPMU':
        value = 'TURPE 4 -BT>36kVA Moyenne Utilisation ';
        break;
      case 'BTSUPCU4':
        value = 'Tarif BT>36kVA Courte Utilisation';
        break;
      case 'BTSUPLU4':
        value = 'Tarif BT>36kVA Longue Utilisation';
        break;
      case 'HTA5':
        value = 'TURPE 4 -HTA avec différenciation temporelle 5 classes';
        break;
      case 'HTA8':
        value = 'TURPE 4 -HTA avec différenciation temporelle 8 classes';
        break;
      case 'HTASANSDIFF':
        value = 'TURPE 4 -HTA sans différenciation temporelle';
        break;
      case 'HTACU5':
        value = 'Tarif HTA Courte Utilisation';
        break;
      case 'HTALU5':
        value = 'Tarif HTA Longue Utilisation';
        break;
      case 'HTACUPM5':
        value = 'Tarif HTA à PM Courte Utilisation';
        break;
      case 'HTALUPM5':
        value = 'Tarif HTA à PM Longue Utilisation';
        break;
      default:
        value = 'FTA invalide';
        break;
    }

    return value;
  }

  public displayPuissanceUniqueHeader(profiles: IProfile[]): string[] {
    const puissanceUnique: Set<string> = new Set([]);
    for (const profile of profiles) {
      if (profile.salesforce?.acheminement?.puissances?.BASE) {
        puissanceUnique.add('Puissance Souscrite');
      }
    }

    return [...puissanceUnique];
  }

  public displayPuissanceHeader(profiles: IProfile[]): string[] {
    const namesOfPostesHoro: Set<string> = new Set([]);
    for (const profile of profiles) {
      if (
        profile.salesforce?.acheminement?.puissances &&
        Object.keys(profile.salesforce.acheminement.puissances)
      ) {
        orderedTemporalClass.forEach((ct) => {
          if (ct === RegulatedTemporalClass.BASE) return;
          if (
            profile.salesforce?.acheminement?.puissances &&
            Object.keys(profile.salesforce.acheminement.puissances).includes(ct)
          ) {
            if (profile.salesforce.acheminement.puissances[ct] !== null)
              namesOfPostesHoro.add(ct);
          }
        });
      }
    }

    const temporalClass = this.sortTemporalClasses([...namesOfPostesHoro]);

    return temporalClass;
  }

  public displayConsommationHeader(profiles: IProfile[]): string[] {
    const namesOfPostesHoro: Set<string> = new Set([]);
    for (const profile of profiles) {
      if (
        profile.salesforce?.consommationData?.consommationParPoste &&
        Object.keys(profile.salesforce.consommationData.consommationParPoste)
      ) {
        orderedTemporalClass.forEach((ct) => {
          if (
            profile.salesforce?.consommationData?.consommationParPoste &&
            Object.keys(
              profile.salesforce.consommationData.consommationParPoste
            ).includes(ct)
          ) {
            if (
              profile.salesforce.consommationData.consommationParPoste[ct] !==
              null
            )
              namesOfPostesHoro.add(ct);
          }
        });
      }
    }

    const temporalClass = this.sortTemporalClasses([...namesOfPostesHoro]);

    if (temporalClass.length > 0) {
      temporalClass.unshift('Durée');
      temporalClass.push('Total');
    }

    return temporalClass;
  }

  public displayFournisseurHeader(profiles: IProfile[]): string[] {
    const namesOfPostesHoro: Set<string> = new Set([]);
    for (const profile of profiles) {
      if (
        profile.salesforce.consommationData?.detailsReleves
          ?.serieMesureFournisseur.serieMesureSummary.consommationParPoste &&
        Object.keys(
          profile.salesforce.consommationData?.detailsReleves
            ?.serieMesureFournisseur.serieMesureSummary.consommationParPoste
        )
      ) {
        orderedTemporalClass.forEach((ct) => {
          if (
            profile.salesforce.consommationData?.detailsReleves
              ?.serieMesureFournisseur.serieMesureSummary
              .consommationParPoste &&
            Object.keys(
              profile.salesforce.consommationData?.detailsReleves
                ?.serieMesureFournisseur.serieMesureSummary.consommationParPoste
            ).includes(ct)
          ) {
            if (
              profile.salesforce.consommationData?.detailsReleves
                ?.serieMesureFournisseur.serieMesureSummary
                .consommationParPoste[ct] !== null
            )
              namesOfPostesHoro.add(ct);
          }
        });
      }
    }

    const temporalClass = this.sortTemporalClasses([...namesOfPostesHoro]);

    if (temporalClass.length > 0) {
      temporalClass.unshift('Durée');
      temporalClass.push('Total');
    }

    return temporalClass;
  }

  private sortTemporalClasses(temporalClass: string[]): string[] {
    temporalClass.sort(function (a, b) {
      const orderA = posteHorosaisonnierOrder[a] || 1000;
      const orderB = posteHorosaisonnierOrder[b] || 1001;
      return orderA - orderB;
    });
    return temporalClass;
  }

  public searchValueByTemporalClass(
    poste: string,
    values: PowerByTemporalClass
  ): string {
    for (const value in values)
      if (poste === value) {
        if (!values[value]) return '';
        return new Intl.NumberFormat('fr-FR', {
          maximumFractionDigits: 2,
        }).format(values[value]);
      }
    return '';
  }

  public searchConsommationValue(
    poste: string,
    profile: NewProfilEnergetique
  ): string {
    for (const value in profile.consommationData?.consommationParPoste)
      if (
        poste === value &&
        profile.consommationData?.consommationParPoste[value]
      )
        return new Intl.NumberFormat('fr-FR', {
          maximumFractionDigits: 2,
        }).format(profile.consommationData.consommationParPoste[value]);

    if (poste === 'Durée') return this.formatPeriod(profile);

    if (poste === 'Total' && profile.consommationData?.consommationTotale)
      return new Intl.NumberFormat('fr-FR', {
        maximumFractionDigits: 2,
      }).format(profile.consommationData.consommationTotale);

    return '';
  }

  public searchConsommationFrnValue(
    poste: string,
    profile: NewProfilEnergetique
  ): string {
    for (const value in profile.consommationData?.detailsReleves
      ?.serieMesureFournisseur.serieMesureSummary.consommationParPoste)
      if (
        poste === value &&
        profile.consommationData?.detailsReleves?.serieMesureFournisseur
          .serieMesureSummary.consommationParPoste[value]
      )
        return new Intl.NumberFormat('fr-FR', {
          maximumFractionDigits: 2,
        }).format(
          profile.consommationData?.detailsReleves?.serieMesureFournisseur
            .serieMesureSummary.consommationParPoste[value]
        );

    if (poste === 'Durée') return this.formatFrnPeriod(profile);

    if (
      poste === 'Total' &&
      profile.consommationData?.detailsReleves?.serieMesureFournisseur
        .serieMesureSummary.consommationTotale
    )
      return new Intl.NumberFormat('fr-FR', {
        maximumFractionDigits: 2,
      }).format(
        profile.consommationData?.detailsReleves?.serieMesureFournisseur
          .serieMesureSummary.consommationTotale
      );

    return '';
  }

  public formatPeriod(profile: NewProfilEnergetique): string {
    if (
      profile.consommationData?.dateDebutReleve &&
      profile.consommationData?.dateFinReleve
    )
      return EnedisProfileService.getPeriodLabel(
        profile.consommationData.dateDebutReleve,
        profile.consommationData.dateFinReleve
      );
    if (profile.consommationData?.dureeReleve) {
      return new Intl.NumberFormat('fr-FR', {
        maximumFractionDigits: 2,
      }).format(profile.consommationData?.dureeReleve);
    }
    return '';
  }

  private formatFrnPeriod(profile: NewProfilEnergetique): string {
    if (
      profile.consommationData?.detailsReleves?.serieMesureFournisseur
        .serieMesureSummary.periodStartDate &&
      profile.consommationData?.detailsReleves?.serieMesureFournisseur
        .serieMesureSummary.periodEndDate
    )
      return EnedisProfileService.getPeriodLabel(
        profile.consommationData?.detailsReleves?.serieMesureFournisseur
          .serieMesureSummary.periodStartDate,
        profile.consommationData?.detailsReleves?.serieMesureFournisseur
          .serieMesureSummary.periodEndDate
      );
    if (
      profile.consommationData?.detailsReleves?.serieMesureFournisseur
        .serieMesureSummary.periodLengthInMonths
    ) {
      return new Intl.NumberFormat('fr-FR', {
        maximumFractionDigits: 2,
      }).format(
        profile.consommationData?.detailsReleves?.serieMesureFournisseur
          .serieMesureSummary.periodLengthInMonths
      );
    }
    return '';
  }
}

export default new SalesforceProfileService();
