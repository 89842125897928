<template>
  <v-dialog v-model="modal" max-width="80%">
    <template v-slot:activator="{ on: dialog }">
      <div>
        <v-btn
          x-small
          block
          plain
          :disabled="!hasProfilesToAdd"
          @click.prevent="filterProfilesToAdd"
          v-on="dialog"
        >
          <v-icon>mdi-plus</v-icon>
          Ajout dans Salesforce
        </v-btn>
      </div>
    </template>

    <div @click.prevent="closeDatalist">
      <v-card min-height="400" class="d-flex flex-column">
        <v-card-title>
          <span class="title__modal">Ajouter des PRM dans Salesforce</span>
        </v-card-title>
        <v-card-text :style="{ minHeight: '350px' }">
          <v-container>
            <v-row class="flex-column">
              <div v-if="!generalError" class="d-flex" style="width: 100%">
                <h4
                  :style="{
                    alignSelf: 'center',
                    marginRight: '3rem',
                    whiteSpace: 'nowrap',
                  }"
                >
                  1. Choisir un compte
                </h4>
                <div
                  :style="{
                    width: '100%',
                    height: '50px',
                  }"
                >
                  <v-form @submit.prevent="resetForm(), findSfdcAccounts()">
                    <v-text-field
                      class="pt-0"
                      v-model="inputAccount"
                      placeholder="nom du compte Salesforce"
                      datalistOpen="datalist"
                      @change="$v.inputAccount.$touch()"
                      @blur="$v.inputAccount.$touch()"
                      :error-messages="[...inputAccountErrors, ...errors]"
                    ></v-text-field>
                  </v-form>
                  <ul class="datalist" v-show="datalistOpen">
                    <li
                      class="datalist__li"
                      v-for="(account, i) in accounts"
                      :key="'accounts' + i"
                      @click="selectAndCloseDatalist(i)"
                    >
                      <p v-if="account.siret" class="datalist__li__value">
                        {{ account.name }} - {{ account.siret }}
                      </p>
                      <p v-else class="datalist__li__value">
                        {{ account.name }}
                      </p>
                      <p class="datalist__li__value">
                        {{ account.ownerName }}
                      </p>
                    </li>
                  </ul>
                </div>
                <button
                  type="button"
                  @click.prevent="resetForm(), findSfdcAccounts()"
                >
                  <v-icon
                    v-if="!searchAccountLoading"
                    id="search"
                    class="mr-2 ml-2"
                    >mdi-magnify</v-icon
                  >
                  <v-progress-circular
                    v-if="searchAccountLoading"
                    indeterminate
                    color="var(--primary)"
                    size="25"
                  ></v-progress-circular>
                </button>
              </div>
              <v-alert
                v-if="generalError"
                align="center"
                dense
                outlined
                type="error"
              >
                <div class="title">Erreur général</div>
                <pre style="overflow: hidden">{{
                  JSON.stringify(generalError, null, '\t')
                }}</pre>
              </v-alert>

              <div
                :style="{
                  minHeight: '2rem',
                }"
              >
                <v-input
                  v-if="errorAssociateAllBtn"
                  :style="{
                    width: 'fit-content',
                    float: 'right',
                    marginRight: '1rem',
                  }"
                  class="error-message"
                  :error-messages="errorAssociateAllBtn"
                >
                </v-input>
              </div>

              <div class="d-flex" style="width: 100%">
                <h4
                  :style="{
                    alignSelf: 'center',
                    marginRight: '3rem',
                    whiteSpace: 'nowrap',
                  }"
                >
                  2. Choisir les PRM
                </h4>
                <table style="text-align: center">
                  <thead>
                    <tr>
                      <th class="pt-2 pb-2">PRM</th>
                      <th>Etat contractuel</th>
                      <th>Adresse</th>
                      <th>
                        Compte SF
                        <v-btn
                          color="var(--secondary)"
                          plain
                          v-if="!generalError"
                          :disabled="disableAssociatedBtn"
                          @click.prevent="associateToAll"
                          class="ml-2 mr-2"
                          :style="{
                            borderBottom: '1px solid white',
                            textTransform: 'lowercase',
                            minWidth: 0,
                            padding: 0,
                            borderRadius: 0,
                            height: '20px',
                            color: 'white',
                            float: 'right',
                          }"
                        >
                          associer tous
                        </v-btn>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(profile, i) in profileList"
                      :key="'profiles' + i"
                    >
                      <td>{{ profile.prm }}</td>
                      <td>
                        <ProfilEtatContrat
                          :style="{ textAlign: 'center' }"
                          :etatContractuel="
                            profile.salesforce.pointLivraison.etatContractuel
                              ? profile.salesforce.pointLivraison
                                  .etatContractuel
                              : 'Aucune information'
                          "
                        ></ProfilEtatContrat>
                      </td>
                      <td>
                        <ProfileAddress
                          v-if="profile.salesforce.pointLivraison.address"
                          :address="
                            parseAdressProfilSF(
                              profile.salesforce.pointLivraison.address
                            )
                          "
                        ></ProfileAddress>
                      </td>
                      <td v-if="!profile.account">
                        <v-btn
                          v-if="!generalError"
                          plain
                          color="var(--secondary)"
                          @click.prevent="associateAccount(profile)"
                          :disabled="disableAssociatedBtn"
                        >
                          ASSOCIER
                        </v-btn>
                      </td>
                      <td v-if="profile.account">
                        <div
                          :style="{ color: 'var(--secondary)' }"
                          class="d-flex justify-space-between"
                        >
                          <div></div>
                          <div>
                            <p :style="{ padding: '0.2rem', margin: 0 }">
                              {{ profile.account.name }}
                            </p>
                            <p :style="{ padding: '0.2rem', margin: 0 }">
                              {{ profile.account.ownerName }}
                            </p>
                          </div>
                          <div :style="{ alignSelf: 'center' }">
                            <v-btn
                              plain
                              @click.prevent="cancelAssociation(profile)"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </v-row>
          </v-container>
        </v-card-text>
        <div class="d-flex justify-center pa-8">
          <v-btn
            color="secondary"
            text
            class="new-button__secondary"
            @click="closeModal"
          >
            Annuler
          </v-btn>
          <v-btn
            v-if="!generalError"
            color="secondary"
            class="new-button"
            @click.prevent="createSfdcProfiles"
            :disabled="disableCreationToSfdcBtn"
          >
            CREER CES PRM DANS SALESFORCE
          </v-btn>
        </div>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
  import socket from '../../socket';
  import { mapState } from 'vuex';
  import { required } from 'vuelidate/lib/validators';
  import { currentSearchMin } from '../../validators/generalValidators';

  import ProfileAddress from '../app/AppProfileAddress';
  import ProfilEtatContrat from '../app/AppProfilEtatContrat';

  export default {
    name: 'ModalAddProfilEnergetiqueSfdc',
    components: {
      ProfileAddress,
      ProfilEtatContrat,
    },
    props: {
      profiles: {
        type: Array,
        required: true,
      },
      hasProfilesToAdd: {
        type: Boolean,
        required: true,
      },
    },
    data() {
      return {
        profileList: [],
        searchAccountLoading: false,
        datalistOpen: false,
        accounts: [],
        inputAccount: '',
        modal: false,
        generalError: null,
        errors: [],
        errorAssociateAllBtn: '',
      };
    },
    watch: {
      inputAccount() {
        this.resetForm();
      },
      profileList() {
        for (const profile of this.profileList) {
          if (!profile.account) return (this.errorAssociateAllBtn = '');
        }
      },
    },
    computed: {
      ...mapState('auth', ['user']),
      disableAssociatedBtn() {
        const existing = this.accounts.find(
          (e) => e.name === this.inputAccount
        );
        if (existing) return false;
        return true;
      },
      disableCreationToSfdcBtn() {
        const associatedProfile = this.profileList.filter((e) => e.account);
        if (associatedProfile.length >= 1) return false;
        return true;
      },
      inputAccountErrors() {
        const errors = [];
        if (!this.$v.inputAccount.$dirty) return errors;
        !this.$v.inputAccount.required &&
          errors.push('Veuillez renseigner un nom de compte');
        return errors;
      },
    },
    created() {
      socket.on('salesforce:accounts:fetch', (response) => {
        if (response.status == 'COMPLETED') {
          this.searchAccountLoading = false;
          if (!response.accounts)
            this.generalError =
              'Une anomalie est survenue durant la récupération des comptes Salesforce';
          else if (response.accounts.length === 0)
            this.errors.push('Aucun compte n’a été trouvé');
          else if (response.accounts.length > 0) this.datalistOpen = true;

          this.accounts = response.accounts;
        } else if (response.status == 'FAILED') {
          this.generalError = response.message;
        }
      });
    },
    validations: {
      inputAccount: { required, currentSearchMin },
    },
    methods: {
      filterProfilesToAdd() {
        this.profileList = [];
        return this.profiles.forEach((profile) => {
          if (
            profile.message === 'PRM absent de salesforce' ||
            profile.message ===
              "PRM absent de salesforce et aucune information ne provenant d'Enedis"
          )
            this.profileList.push(profile);
        });
      },
      closeModal() {
        this.modal = false;
      },
      async findSfdcAccounts() {
        if (this.checkForm() && !this.$v.$invalid) {
          try {
            this.accounts = [];
            this.searchAccountLoading = true;
            const response = await this.$http.get('/salesforce/accounts', {
              params: {
                currentSearch: this.inputAccount,
              },
              withCredentials: true,
            });
            this.checkData(response);
          } catch (err) {
            if (
              err.response &&
              err.response.data &&
              err.response.data.message
            ) {
              this.generalError = err.response.data.message;
            } else {
              this.generalError = err.message;
            }
          }
        }
      },
      async createSfdcProfiles() {
        if (this.checkForm() && !this.$v.$invalid) {
          try {
            this.accounts = [];
            this.closeModal;

            const profiles = this.profileList
              .filter((e) => e.account)
              .map((e) => {
                return {
                  prm: e.prm,
                  account: e.account,
                  pointLivraison: e.salesforce.pointLivraison,
                  creator: {
                    displayName: this.user.displayName,
                    id: this.formatUserId(),
                  },
                };
              });

            const response = await this.$http.post(
              '/salesforce/profiles',
              {
                params: {
                  profiles,
                },
              },
              { withCredentials: true }
            );
            this.checkData(response);
            this.$emit('createProfileSfdc', profiles);
            this.closeModal();
          } catch (err) {
            if (
              err.response &&
              err.response.data &&
              err.response.data.message
            ) {
              this.generalError = err.response.data.message;
            } else {
              this.generalError = err.message;
            }
          }
        }
      },
      formatUserId() {
        const userId = this.user.id.split('/');
        return userId[1];
      },
      checkData(response) {
        if (!response.data) throw new Error('Aucune données disponible');
      },
      checkForm() {
        this.$v.$touch();
        return !this.$v.$invalid;
      },
      selectAndCloseDatalist(i) {
        this.inputAccount = this.accounts[i].name;
        this.datalistOpen = false;
      },
      closeDatalist(event) {
        if (!event.target.id) this.datalistOpen = false;
      },
      parseAdressProfilSF(adresse) {
        return {
          numeroEtNomVoie: adresse.numeroEtNomVoie,
          codePostal: adresse.codePostal,
          commune: { libelle: adresse.commune.libelle },
        };
      },
      associateAccount(profile) {
        const account = this.accounts.find((e) => e.name === this.inputAccount);
        const profileWithAccount = { ...profile, account };
        this.profileList = this.profileList.map((e) => {
          if (e.prm === profile.prm) {
            return profileWithAccount;
          }
          return e;
        });
      },
      associateToAll() {
        const account = this.accounts.find((e) => e.name === this.inputAccount);
        const remainingProfiles = this.profileList.filter((e) => !e.account);
        if (remainingProfiles.length === 0)
          this.errorAssociateAllBtn = 'Tous les PRM sont associés à un compte';
        this.profileList = this.profileList.map((e) => {
          if (!e.account) return { ...e, account };
          return e;
        });
      },
      cancelAssociation(profile) {
        delete profile.account;
        this.profileList = this.profileList.map((e) => {
          if (e.prm === profile.prm) {
            return profile;
          }
          return e;
        });
      },
      resetForm() {
        this.errors = [];
        this.datalistOpen = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/client/style/constants.scss';
  @import 'src/client/style/table.scss';
  .title {
    &__modal {
      font-size: 1.5em;
      color: var(--grey);
    }
  }
  .datalist {
    bottom: 1rem;
    width: 100%;
    max-height: 350px;
    position: relative;
    z-index: 1000;
    list-style: none;
    margin: 0;
    padding: 5px 0;
    background-color: white;
    border: 1px #ccc solid;
    box-shadow: 0px 3px 4px rgba(172, 171, 171, 0.705);
    overflow: auto;

    &__li {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      font-size: 1em;

      &:hover {
        background-color: #ccc;
      }

      &__value {
        margin: 0;
        padding: 1rem;
      }
    }
  }

  .new-button {
    padding: 2rem !important;
    border-radius: 0;

    &__secondary {
      padding: 2rem !important;
    }
  }
</style>
